import {
  Button,
  Collapse,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import { useEffect } from "react";
import { useState } from "react";

const responsiveScreenSize = "sm";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
    flexDirection: "row",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      flexDirection: "column",
    },
  },
  sideTextContainer: {
    [theme.breakpoints.down(responsiveScreenSize)]: {
      marginBottom: "20px",
    },
  },
  sideTextHeader: {
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "2rem",
    },
    fontFamily: "Montserrat",
    border: "#ededed",
    color: "#ededed",
    padding: "20px 55px",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      padding: "30px 16px",
    },
    fontSize: "3.5rem",
  },
  sideTextDesc: {
    color: "white",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "0.8rem",
      padding: "0px 16px",
      marginBottom: "10px",
    },
    fontSize: "1.1rem",
    fontFamily: "Montserrat",
    padding: "0px 55px",
  },
  together: {
    backgroundColor: "white",
    color: "black",
  },
  socialButtonsContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  socialButton: {
    margin: "5px",
    padding: "20px",
    color: "white",
    fontFamily: "Montserrat",
    borderColor: "white",
    width: "300px",
    transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
    "&:hover": {
      boxShadow: "inset 300px 0 0 0 white",
      color: "black",
    },
  },
}));

function Contact() {
  const classes = useStyles();
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    setIsCollapsed(true);
  }, []);
  return (
    <Container className={classes.root}>
      <Container className={classes.sideTextContainer}>
        <Collapse in={isCollapsed} {...(isCollapsed ? { timeout: 1000 } : {})}>
          <Typography className={classes.sideTextHeader}>
            Let's build something{" "}
            <span className={classes.together}>together!</span>
          </Typography>
        </Collapse>
        <Collapse in={isCollapsed} {...(isCollapsed ? { timeout: 1000 } : {})}>
          <Typography className={classes.sideTextDesc}>
            You can use any of the Social Medias to get to me.
          </Typography>
        </Collapse>
      </Container>
      <Container className={classes.socialButtonsContainer}>
        <Button
          className={classes.socialButton}
          variant="outlined"
          size="large"
          startIcon={<GitHubIcon />}
          href="https://github.com/kharwar"
          target="_blank"
        >
          Github
        </Button>
        <Button
          className={classes.socialButton}
          variant="outlined"
          size="large"
          startIcon={<LinkedInIcon />}
          href="https://www.linkedin.com/in/siddharth-kharwar-1b94901a4/"
          target="_blank"
        >
          LinkedIn
        </Button>
        <Button
          className={classes.socialButton}
          variant="outlined"
          size="large"
          startIcon={<InstagramIcon />}
          href="https://www.instagram.com/syddhrth/"
          target="_blank"
        >
          Instagram
        </Button>
        <Button
          className={classes.socialButton}
          variant="outlined"
          size="large"
          startIcon={<EmailIcon />}
          onClick={() => (window.location = "mailto:ksiddharth127@gmail.com")}
        >
          ksiddharth127@gmail.com
        </Button>
      </Container>
    </Container>
  );
}

export default Contact;
