export const SidebarData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About Me",
    path: "/about-me",
  },
  {
    title: "My Work",
    path: "/work",
  },
  // {
  //   title: "Skills",
  //   path: "/skills",
  // },
  {
    title: "Contact",
    path: "/contact",
  },
];
