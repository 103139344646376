import { IconButton, makeStyles, Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  MoveOut,
  MoveIn,
  Sticky,
} from "react-scroll-motion";
const responsiveScreenSize = "sm";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    minWidth: "100vw",
    transition: "opacity .3s ease-in-out",
    opacity: 0.1,
  },
  introText: {
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "2rem",
    },
    fontFamily: "Montserrat",
    border: "#ededed",
    color: "#ededed",
    padding: "20px 55px",
    fontSize: "3.5rem",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  icon: {
    color: "white",
    fontSize: "2rem",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "1.5rem",
    },
  },
}));
function Introduction() {
  const classes = useStyles();
  return (
    <ScrollContainer className={classes.root}>
      <ScrollPage id="intro" page={0}>
        <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -250))}>
          <Typography className={classes.introText}>hello.</Typography>
        </Animator>
      </ScrollPage>

      <ScrollPage page={1}>
        <Animator animation={batch(Fade(), MoveIn(), Sticky())}>
          <div className={classes.wrapper}>
            <Typography className={classes.introText}>
              My name is Siddharth.
            </Typography>
            <IconButton>
              <Link to="/about-me">
                <ArrowForwardIosIcon className={classes.icon} />
              </Link>
            </IconButton>
          </div>
        </Animator>
      </ScrollPage>
    </ScrollContainer>
  );
}

export default Introduction;
