import "./App.css";

import { makeStyles } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Appbar from "./pages/Appbar/Appbar";
import particles from "./assets/particlesjs-config.json";
import Particles from "react-particles-js";
import Introduction from "./pages/Home/Introduction";
import AboutMe from "./pages/AboutMe/AboutMe";
import Work from "./pages/Work/Work";
import Contact from "./pages/Contact/Contact";
import Assistant from "./pages/Assistant/Assistant";

const useStyle = makeStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
  },
  particles: {
    backgroundColor: "#0d0d0d",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundSize: "auto",
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    objectFit: "cover",
    height: "100vh",
    minWidth: "100vw",
    zIndex: -1,
  },
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function App() {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Particles params={particles} className={classes.particles} />
      <Assistant />
      <Router>
        <Appbar />
        <Switch>
          <Route exact path="/">
            <Introduction />
          </Route>
          <Route exact path="/about-me">
            <AboutMe />
          </Route>
          <Route exact path="/work">
            <Work />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
