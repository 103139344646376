import { Card, Grid, Grow, Typography } from "@material-ui/core";
import { useEffect, useRef } from "react";

function ChatMessage({ message, classes }) {
  const isUser = message.sentBy === "user";
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behaviour: "smooth",
      });
    }
  }, []);

  return (
    <Grow in={true}>
      <Grid container style={{ marginBottom: "10px" }} ref={ref}>
        <Grid item xs={isUser ? 2 : false}></Grid>

        <Grid item xs={10}>
          <Card
            className={`${classes.messageParent} ${
              message.sentBy === "system"
                ? classes.systemMessage
                : classes.userMessage
            }`}
          >
            <Grid container wrap="nowrap">
              <Grid item xs>
                <Typography>{message.message}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grow>
  );
}

export default ChatMessage;
