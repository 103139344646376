import { Collapse, Container, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const responsiveScreenSize = "sm";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat",
  },
  introText: {
    fontFamily: "Montserrat",
    border: "#ededed",
    color: "#ededed",
    fontSize: "1.5rem",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "1rem",
    },
    textAlign: "center",
  },
  siddharth: {
    background: "white",
    color: "black",
  },
  connect: {
    textDecoration: "underline",
    color: "white",
    transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
    "&:hover": {
      boxShadow: "inset 300px 0 0 0 white",
      color: "black",
    },
    image: {
      objectFit: "contain",
    },
  },
}));

function AboutMe() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  useEffect(() => {
    setIsCollapsed(true);
  }, []);

  const classes = useStyles();
  return (
    <>
      <Container className={classes.root}>
        <Collapse in={isCollapsed} {...(isCollapsed ? { timeout: 1000 } : {})}>
          <Typography className={classes.introText}>
            Hey there, folks! I'm{" "}
            <span className={classes.siddharth}>
              Siddharth, your friendly neighborhood tech enthusiast and coding
              aficionado!{" "}
            </span>
            <br />
            I'm a software developer with a passion for learning and exploring
            new technologies that keeps me on the lookout for exciting
            challenges. When I'm not sipping on a cappuccino, you'll find me
            hunting down bugs and unraveling complex issues. I love the thrill
            of problem-solving and the never-ending game it presents. <br />
            <br />
            Let's{" "}
            <Link to="/contact">
              <span className={classes.connect}> connect and chat </span>{" "}
            </Link>
            – I'm always up for discussing all things tech! Together, we can
            create something truly remarkable.
          </Typography>
        </Collapse>
      </Container>
    </>
  );
}

export default AboutMe;
