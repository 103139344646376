import dalSocial from "../../assets/dalSocialLogin.png";
import dalMedia from "../../assets/dalMedia.png";
import dbms from "../../assets/DBMS.png";
import dalBooks from "../../assets/Book Management.png";
export const projects = [
  {
    header: "DalMedia",
    content: `Dal Media is a social networking application for Dalhousie students and professors. The main goal of the platform is to provide an informal way of connecting with the peers and seniors.`,
    image: dalMedia,
    url: "https://github.com/kharwar/dal-media",
  },
  {
    header: "Distributed DBMS",
    content: `D2_DB is a distributed database management system that manages the data in its rawest form. The system splits the data horizontally across two different servers hosted on Google Compute Engine VM using SSH and SCP protocols.`,
    image: dbms,
    url: "https://github.com/kharwar/distributed-database-management-system",
  },
  {
    header: "DalBooks",
    content: `DalBooks is basically a Book Barter System that uses various services that make it completely serverless.`,
    image: dalBooks,
    url: "https://github.com/kharwar/dal-books",
  },
  {
    header: "DalSocial",
    content: `An app suitable for all Dal students trying to build a community by meeting up with like-minded people and interacting with them.`,
    image: dalSocial,
    url: "https://github.com/kharwar/dal-social",
  },
];
