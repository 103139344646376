import {
  Box,
  Card,
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  TextField,
} from "@material-ui/core";
import { Assistant as AssistantIcon, Close, Send } from "@material-ui/icons";
import { useEffect, useState } from "react";
import ChatMessage from "./ChatMessage";
import axios from "axios";
import Typing from "./Typing";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 1,
  },
  chatRoot: {
    backgroundColor: "#ebebeb",
    position: "fixed",
    zIndex: 2,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    width: "30vw",
    height: "75vh",
    padding: theme.spacing(2),
  },
  closeBtn: {
    color: "black",
    transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
    "&:hover": {
      boxShadow: "inset 200px 0 0 0 black",
      color: "white",
    },
    right: 0,
    position: "absolute",
  },
  sendBtn: {
    transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
    "&:hover": {
      boxShadow: "inset 200px 0 0 0 black",
      color: "white",
    },
    position: "absolute",
    right: 0,
  },
  messageBox: {
    position: "relative",
    height: "10%",
    flexDirection: "row",
    width: "100%",
  },
  messageGrid: {
    display: "flex",
    alignItems: "center",
  },
  messageInput: {
    width: "100%",
    color: "white",
  },
  textFieldOutline: {
    borderRadius: "3rem",
    borderColor: "#0d0d0d !important",
    transition: "border .1s ease-in-out",
  },
  topBarHolder: {
    position: "relative",
    height: "10%",
    display: "flex",
    alignItems: "center",
  },
  nameHolder: {
    position: "absolute",
    left: 0,
    display: "flex",
    alignItems: "center",
  },
  chatsContainer: {
    height: "100%",
    padding: theme.spacing(1),
    maxHeight: "100%",
    overflowY: "auto",
  },
  messageParent: {
    padding: theme.spacing(1),
    // backgroundColor: "#0d0d0d",
    // color: "white",
  },
  systemMessage: {
    backgroundColor: "#d0d0d0",
    color: "#0d0d0d",
  },
  userMessage: {
    backgroundColor: "#0d0d0d",
    color: "white",
  },
}));

function Assistant() {
  const classes = useStyles();

  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState([]);

  const [userMessage, setUserMessage] = useState("");

  const [isTyping, setIsTyping] = useState(false);

  const onMessageType = (e) => {
    setUserMessage(e.target.value);
  };

  const onMessageSend = async () => {
    if (userMessage !== "") {
      setMessages([
        ...messages,
        {
          message: userMessage,
          sentBy: "user",
        },
      ]);
      setUserMessage("");
    }
  };

  const newMessageHandler = async (message) => {
    setIsTyping(true);
    const result = await axios.post(
      "https://assistant-rag.azurewebsites.net/message",
      {
        query: message,
      }
    );
    const newMessage = {
      sentBy: "system",
      message: result.data,
    };
    setIsTyping(false);
    setMessages([...messages, newMessage]);
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      onMessageSend();
    }
  };

  useEffect(() => {
    setMessages([
      {
        sentBy: "system",
        message:
          "Hi, this is Siddharth's Assistant! I would love to help you out in case you had any questions regarding Siddharth's profile :)",
      },
    ]);
  }, []);

  useEffect(() => {
    const lastMessage = messages.at(messages.length - 1);
    if (lastMessage?.sentBy === "user") {
      newMessageHandler(lastMessage.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  return (
    <>
      <Fab
        aria-label="assistant"
        className={classes.fab}
        onClick={() => setShow(true)}
      >
        <AssistantIcon />
      </Fab>
      <Slide direction="left" in={show} mountOnEnter unmountOnExit>
        <Card className={classes.chatRoot}>
          <Box style={{ height: "100%" }}>
            <Box className={classes.topBarHolder}>
              <IconButton
                className={classes.closeBtn}
                onClick={() => setShow(false)}
              >
                <Close />
              </IconButton>
            </Box>
            <Box style={{ height: "75%" }}>
              <Container className={classes.chatsContainer}>
                {messages.map((message, index) => {
                  return (
                    <ChatMessage
                      key={index}
                      message={message}
                      classes={classes}
                    />
                  );
                })}
              </Container>
            </Box>
            <Box style={{ height: "5%" }}>{isTyping && <Typing />}</Box>
            <Box className={classes.messageBox}>
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <TextField
                    className={classes.messageInput}
                    placeholder="Message Sid's Assistant"
                    variant="outlined"
                    value={userMessage}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.textFieldOutline,
                      },
                    }}
                    onKeyPress={onEnterPress}
                    onChange={onMessageType}
                  ></TextField>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.sendBtn}
                    onClick={() => onMessageSend()}
                  >
                    <Send />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </Slide>
    </>
  );
}

export default Assistant;
