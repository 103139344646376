import React, { useState } from "react";
import {
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import { SidebarData } from "./SidebarData";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const responsiveScreenSize = "sm";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#fff",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "1.5rem",
    },
  },
  paper: {
    zIndex: 3,
    backgroundColor: "black",
    width: "500px",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      width: "200px",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listItem: {
    border: "#ededed",
    color: "#ededed",
    padding: "20px",
    textAlign: "center",
    transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
    "&:hover": {
      boxShadow: "inset 200px 0 0 0 white",
      color: "black",
    },
  },
  listText: {
    fontFamily: "Montserrat",
    fontSize: "2em",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "1rem",
    },
  },
  invisible: {
    visibility: "hidden",
  },
}));

function SideNav() {
  const [click, setClick] = useState(false);
  const classes = useStyles();
  return (
    <>
      <IconButton onClick={() => setClick(!click)}>
        {
          <SortIcon
            className={`${classes.icon} ${click && classes.invisible}`}
          />
        }
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={click}
        onClose={() => setClick(false)}
        elevation={0}
        classes={{ paper: classes.paper }}
      >
        <List classes={classes.list}>
          {SidebarData.map((item, index) => {
            return (
              <Link
                key={index}
                to={item.path}
                style={{ textDecoration: "none" }}
              >
                <ListItem
                  button
                  className={classes.listItem}
                  onClick={() => setClick(false)}
                >
                  <ListItemText classes={{ primary: classes.listText }}>
                    {item.title}
                  </ListItemText>
                </ListItem>
              </Link>
            );
          })}
        </List>
      </SwipeableDrawer>
    </>
  );
}

export default SideNav;
