import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  "@keyframes blink": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
  blinkingAnimation: {
    animation: "$blink 1.5s infinite",
    animationFillMode: "both",
    marginLeft: theme.spacing(1),
    fontStyle: "italic",
  },
}));

const Typing = () => {
  const classes = useStyles();
  return (
    <div class="typing">
      <Typography className={classes.blinkingAnimation} variant="subtitle2">
        typing...
      </Typography>
    </div>
  );
};

export default Typing;
