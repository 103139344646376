import React, { useEffect, useState } from "react";

import { AppBar, makeStyles, Toolbar, Typography } from "@material-ui/core";
import SideNav from "../SideNav/SideNav";

const responsiveScreenSize = "sm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appBar: {
    [theme.breakpoints.down(responsiveScreenSize)]: {
      height: "70px",
    },
    background: "none",
    height: "100px",
    transitionTimingFunction: "ease-in",
    transition: "all 0.5s",
  },
  appBarColor: {
    background: "black",
  },
  icon: {
    fontSize: "2rem",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "1.5rem",
    },
    color: "white",
  },
  appBarWrapper: {
    [theme.breakpoints.down(responsiveScreenSize)]: {
      paddingTop: "10px",
    },
    paddingTop: "20px",
    width: "90%",
    margin: "0 auto",
    color: "white",
  },
  appBarTitle: {
    flexGrow: "1",
    fontFamily: "Montserrat",
    fontWeight: 300,
    fontSize: "2rem",
    [theme.breakpoints.down(responsiveScreenSize)]: {
      fontSize: "1rem",
    },
  },
  kharwar: {
    fontWeight: 400,
  },
}));
function Appbar() {
  const [nav, setNav] = useState(false);
  useEffect(() => {
    // window.scrollTo(0, 0);
    function eventListener() {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
          setNav(true);
        } else {
          setNav(false);
        }
      });
    }
    console.log();
    eventListener();
    return () => {
      window.removeEventListener("scroll", eventListener);
    };
  }, []);
  const classes = useStyles();
  return (
    <div className={classes.root} id="header">
      <AppBar
        className={`${classes.appBar} ${nav && classes.appBarColor}`}
        elevation={0}
      >
        <Toolbar className={`${classes.appBarWrapper}`}>
          <Typography className={classes.appBarTitle}>
            Siddharth <span className={classes.kharwar}>Kharwar</span>
          </Typography>
          {<SideNav />}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Appbar;
