import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import {
  Button,
  Collapse,
  Container,
  Fade,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useReducer } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    flexDirection: "row",
    height: "100vh",
    width: "100vw",
  },
  arrow: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
    padding: "50px",
    color: "white",
    transition: "color .4s ease-in-out, box-shadow .4s ease-in-out",
    "&:hover": {
      boxShadow: "inset 300px 0 0 0 white",
      color: "black",
    },
  },
  item: {
    padding: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    width: "80vw",
    [theme.breakpoints.down("sm")]: {
      height: "60%",
      width: "90vw",
      flexDirection: "column",
      padding: "0px",
    },
  },
  itemContentHolder: {
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      flex: 0,
    },
  },
  itemHeader: {
    fontSize: "2em",
    fontFamily: "Montserrat",
    border: "#ededed",
    color: "#ededed",
    padding: "20px 0px",
  },
  itemDesc: {
    fontSize: "1em",
    color: "white",
    fontFamily: "Montserrat",
  },
  itemImageHolder: {
    padding: "20px",
    height: "70%",
    justifyContent: "center",
    flex: 7,
    [theme.breakpoints.down("sm")]: {
      flex: 0,
      padding: "20px 0px",
    },
  },
  itemImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  collapseContainer: {
    transition: theme.transitions.create("height"),
    overflow: "hidden",
  },
  fadeContainer: {
    transition: theme.transitions.create("opacity"),
  },
  socialButton: {
    marginTop: "20px",
    padding: "10px 20px",
    color: "white",
    fontFamily: "Montserrat",
    borderColor: "white",
    transition: "color .3s ease-in-out, box-shadow .3s ease-in-out",
    "&:hover": {
      boxShadow: "inset 300px 0 0 0 white",
      color: "black",
    },
  },
}));

const Carousel = ({ data }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const UPDATE_PROJ = "UPDATE_PROJ";
  const reducer = (state, action) => {
    if (action.type === UPDATE_PROJ) {
      return data[action.newIndex];
    }
  };

  const [project, dispatchProj] = useReducer(reducer, data[0]);
  const onNextClick = () => {
    setIsCollapsed(false);
    const newIndex = index === data.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
  };

  const onPrevClick = () => {
    setIsCollapsed(false);
    const newIndex = index === 0 ? data.length - 1 : index - 1;
    setIndex(newIndex);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatchProj({
        type: UPDATE_PROJ,
        newIndex: index,
      });
      setIsCollapsed(true);
    }, 1000);
  }, [index]);

  useEffect(() => {
    setIsCollapsed(true);
  }, []);

  return (
    <Container className={classes.root}>
      <IconButton className={classes.arrow} onClick={onPrevClick}>
        <ArrowBackIos />
      </IconButton>
      <Container className={classes.item}>
        <Container className={classes.itemContentHolder}>
          <Collapse
            in={isCollapsed}
            classes={{ container: classes.collapseContainer }}
            timeout={1000}
          >
            <Typography className={classes.itemHeader} id="head">
              <b>{project.header}</b>
            </Typography>
          </Collapse>
          <Collapse
            in={isCollapsed}
            timeout={1000}
            classes={{ container: classes.collapseContainer }}
          >
            <Typography className={classes.itemDesc} id="content">
              {project.content}
            </Typography>
          </Collapse>
          <Collapse
            in={isCollapsed}
            timeout={1000}
            classes={{ container: classes.collapseContainer }}
          >
            <Button
              href={project.url}
              target="_blank"
              variant="outlined"
              className={classes.socialButton}
            >
              View Project
            </Button>
          </Collapse>
        </Container>

        <Container className={classes.itemImageHolder} id="img">
          <Fade in={isCollapsed} timeout={1000}>
            <img
              src={project.image}
              alt="Project Thumb"
              className={classes.itemImage}
            />
          </Fade>
        </Container>
      </Container>
      <IconButton className={classes.arrow} onClick={onNextClick}>
        <ArrowForwardIos />
      </IconButton>
    </Container>
  );
};

export default Carousel;
